import React from "react"
import PropTypes from "prop-types"
import { Box } from "adminlte-2-react"
import CircularProgress from "@material-ui/core/CircularProgress"

let _this
export class PayloadTxId extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inFetch: true,
      error: false,
      icon: '',
      txId: ''
    }
    _this = this
  }

  render() {
    const { inFetch, error, icon, txId } = _this.state
    return (
      <Box
        collapsable={true}
        title="Payload Transaction ID"
        icon={icon || ''}
        className={`fade-in proof-content ${!inFetch && error ?
          'proof-icon-wrong' :
          'proof-icon-check'}`
        } >

        <p>
          This is the transaction on the Bitcoin Cash (BCH) blockchain that recorded
          the document proof of existence at a specific time. The document
          SHA256 hash or 'fingerprint' is written to the blockchain when your
          document is uploaded to our system.
        </p>

        {inFetch ? <CircularProgress className="proof-spinner" /> :
          <a
            rel="noreferrer"
            target="_blank"
            href={`https://explorer.bitcoin.com/bch/tx/${txId}`}>
            {txId ? txId : ""}
          </a>
        }
        {error && <p>{error}</p>}

      </Box>
    )
  }

  async componentDidMount() {
    setTimeout(async () => {
      await _this.setPayloadTxId()

    }, 1000);
  }


  async setPayloadTxId() {
    try {
      const { txId } = _this.props
      if(!txId){
        throw new Error("Transaction ID Not Found!")
      }
      // handle test status
      _this.setState({
        inFetch: false,
        txId: txId,
        icon: 'fa-check'
      })


      _this.props.nextStep()
    } catch (error) {
      console.error(error)
      // handle test status
      _this.setState({
        inFetch: false,
        icon: 'fa-times-circle',
        error: error.message
      })

      _this.props.nextStep(true)

    }
  }


  openExplorer() {
    window.open(
      `https://explorer.bitcoin.com/bch/tx/${_this.state.txId}`,
      "_blank"
    )
  }


}
PayloadTxId.propTypes = {
  txId: PropTypes.string,
  nextStep: PropTypes.func,
}
