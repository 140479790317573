import React from "react"
import PropTypes from "prop-types"
import { Box } from "adminlte-2-react"
import CircularProgress from "@material-ui/core/CircularProgress"

let _this
export class Sha256Hash extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inFetch: true,
      error: false,
      icon: '',
      sha256Hash: ''
    }
    _this = this
  }

  render() {
    const { inFetch, error, icon, sha256Hash } = _this.state
    return (
      <Box
        collapsable={true}
        title="Sha256 Hash"
        icon={icon || ''}
        className={`fade-in proof-content ${!inFetch && error ?
          'proof-icon-wrong' :
          'proof-icon-check'}`
        } >

        <p>
          This is the SHA256 hash of the original document. It is a unique fingerprint
          that cannot be faked. This value was recorded when the document was
          first uploaded to our server.
          Comparing fingerprints proves that the document
          is original and has not been modified in any way.
        </p>

        {inFetch ? <CircularProgress className="proof-spinner" /> :
          <p>{sha256Hash}</p>
        }
        {error && <p>{error}</p>}

      </Box>
    )
  }
  componentDidMount() {
    setTimeout(() => {
      _this.setSha256Hash()
    }, 1000);
  }
  // Add sha256 hash into state object
  setSha256Hash() {
    try {
      const { sha256Hash } = _this.props
      if (!sha256Hash) {
        throw new Error('Hash not found')
      }
      // handle test status
      _this.setState({
        inFetch: false,
        icon: 'fa-check',
        sha256Hash: sha256Hash
      })
      _this.props.nextStep()
    } catch (error) {
      // handle test status
      _this.setState({
        inFetch: false,
        icon: 'fa-times-circle',
        error: error.message
      })
      _this.props.nextStep(true)
    }
  }

}
Sha256Hash.propTypes = {
  sha256Hash: PropTypes.string,
  nextStep: PropTypes.func,
}
