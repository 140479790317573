import React from "react"
import PropTypes from "prop-types"
import { Box } from "adminlte-2-react"
import CircularProgress from "@material-ui/core/CircularProgress"

let _this
export class MetadataTxId extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inFetch: true,
      error: false,
      icon: '',
      txId: ''
    }
    _this = this
  }

  render() {
    const { inFetch, error, icon, txId } = _this.state
    return (
      <Box
        collapsable={true}
        title="Metadata Transaction ID"
        icon={icon || ''}
        className={`fade-in proof-content ${!inFetch && error ?
          'proof-icon-wrong' :
          'proof-icon-check'}`
        } >

        <p>
          This is another transaction on the Bitcoin Cash (BCH) blockchain. It
          is an encrypted pointer to metadata about the document. This
          ensures information about the document cannot be tampered with
          or inappropriately modified.
        </p>

        {inFetch ? <CircularProgress className="proof-spinner" /> :
          <a
            rel="noreferrer"
            target="_blank"
            href={`https://explorer.bitcoin.com/bch/tx/${txId}`}>
            {txId ? txId : ""}
          </a>
        }
        {error && <p>{error}</p>}

      </Box>
    )
  }

  async componentDidMount() {
    setTimeout(async () => {
      await _this.getTxid(_this.props.metadataId)

    }, 1000);


  }


  async getTxid(id) {
    try {
      const txId = _this.props.metadataTxId
      if (!txId) {
        throw new Error("Error Fetching Transaction id")
      }

      // handle test status
      _this.setState({
        inFetch: false,
        txId,
        icon: 'fa-check'
      })

      _this.props.nextStep()

   
    } catch (error) {
      // handle test status
      _this.setState({
        inFetch: false,
        icon: 'fa-times-circle',
        error: error.message
      })

      _this.props.nextStep(true)

    }
  }


  openExplorer() {
    window.open(
      `https://explorer.bitcoin.com/bch/tx/${_this.props.metadataTxId}`,
      "_blank"
    )
  }


}
MetadataTxId.propTypes = {
  metadataTxId: PropTypes.string,
  nextStep: PropTypes.func,
}
