import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { getMacroProof } from "../../services/payload"
import { isLoggedIn } from "../../services/auth"
import CircularProgress from "@material-ui/core/CircularProgress"
import { MetadataTxId } from "./metadata-transaction"
import { PayloadTxId } from "./payload-transaction"

//import { OpReturn } from './op-return'
// import { IpfsMetadata } from './ipfs-metadata'
//import { IpfsHash } from './ipfs-hash'
import { Sha256Hash } from "./sha256"
import { Sha256Hashe2e } from "./sha256-e2e"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./proof.css"

let _this
class Proof extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      payload: "",
      inFetch: true,
      error: false,
      step: 0,
      txId: "",
      stepsAmount: 4,
      failed: 0,
      finished: false,
      macroProof: "",
    }
    _this = this
  }

  render() {
    const {
      step,
      payload,
      inFetch,
      stepsAmount,
      failed,
      finished,
      macroProof,
    } = _this.state

    return (
      <>
        {inFetch ? (
          <div className="progress-container">
            <CircularProgress />
          </div>
        ) : (
          <div className="proof-container" id="proof-container">
            {/* On Error Fetching Payload*/}

            {_this.state.error && (
              <div className="error">
                <h2>File Not Found!</h2>
              </div>
            )}

            {payload.title && (
              <div className="proof-header">
                <h1>{payload.title}</h1>
                <h1>
                  {step}/{stepsAmount}
                </h1>
              </div>
            )}

            <hr></hr>
            {/*Payload Transaction ID */}
            {step >= 1 && (
              <PayloadTxId
                txId={macroProof.fileTxId}
                nextStep={_this.nextStep}
              />
            )}
            <hr></hr>

            {/*Metadata Transaction ID */}
            {step >= 2 && (
              <MetadataTxId
                metadataTxId={macroProof.jsonTxId}
                nextStep={_this.nextStep}
              />
            )}
            <hr></hr>
            {/* IPFS Hash */}
            {/*           { 
              step >= 3 &&
              <IpfsHash
                ipfsHash={payload.ipfsOriginalHash}
                nextStep={_this.nextStep}

              />
            } */}
            <hr></hr>
            {/* Sha256 Hash */}
            {step >= 3 && (
              <Sha256Hash
                sha256Hash={macroProof.sha256Hash}
                nextStep={_this.nextStep}
              />
            )}
            {step >= 4 && (
              <Sha256Hashe2e
                sha256Proof={macroProof.sha256Proof}
                sha256Hash={macroProof.sha256Hash}
                nextStep={_this.nextStep}
              />
            )}

            {/* OP_RETURN */}

            {/*          {step >= 6 &&
              <OpReturn
                txId={txId}
                nextStep={_this.nextStep}
              />}
 */}

            {/* IPFS METADATA*/}
            {/*           {step >= 6 &&
              <IpfsMetadata
                metadataId={payload.meta.metadata._id}
                nextStep={_this.nextStep}
              />
            } */}

            {finished && step === stepsAmount && (
              <FontAwesomeIcon
                className={failed > 0 ? "icon-face-sad" : "icon-face-happy"}
                size="lg"
                icon={failed > 0 ? "sad-tear" : "smile-beam"}
              />
            )}
            <div id="result">
              {finished && failed > 0 && (
                <h3>
                  {failed} of {stepsAmount} Test Failed
                </h3>
              )}
              {finished && failed === 0 && <h3>All Test Passed!</h3>}
            </div>
          </div>
        )}
      </>
    )
  }
  nextStep(isFailed) {
    const { failed, step, stepsAmount } = _this.state
    const _failed = isFailed ? failed + 1 : failed
    const finished = step + 1 > stepsAmount ? true : false
    _this.setState({
      step: finished ? step : step + 1,
      failed: _failed,
      finished,
    })

    if (finished) {
      setTimeout(() => {
        const element = document.getElementById("proof-container")
        if (element)
          element.scrollIntoView({ block: "end", behaviour: "smooth" })
      }, 1000)
    }
  }
  async componentDidMount() {
    // If the user is not logged in, navigate them to the login page.
    if (!isLoggedIn()) {
      navigate(`/`)
    }
    setTimeout(async () => {
      await _this.getProof()
    }, 1000)
  }

  async getProof() {
    try {
      const payloadId = _this.props.payloadId
      if (!payloadId) {
        throw new Error("Error : PayloadId Not Found")
      }

      const macroProof = await getMacroProof(payloadId)
      if (!macroProof.metadata) {
        throw new Error("Error Fetching payload")
      }
      _this.setState({
        payload: macroProof.metadata,
        macroProof: macroProof,
      })
      _this.setTxId(macroProof.jsonTxId)
      _this.nextStep()
      _this.switchSpinner()
      return macroProof
    } catch (error) {
      console.error(error)
      _this.setState({
        error: true,
      })
    }
  }

  switchSpinner() {
    _this.setState({
      inFetch: false,
    })
  }

  setTxId(id) {
    _this.setState({
      txId: id,
    })
  }
}
Proof.propTypes = {
  payloadId: PropTypes.string,
}

export default Proof
