import React from "react"
import PropTypes from "prop-types"
import withLocation from "../components/withLocation"


let Proof =
  typeof window !== `undefined`
    ? require("../components/proof").default
    : null


let _this
class ProofPage extends React.Component {
  constructor(props, ) {
    super(props)
    this.state = {
      payloadId: props.search.id
    }
    _this = this

  }

  render() {
    return <>
      {
        typeof window !== `undefined` &&
        <Proof payloadId={_this.state.payloadId} />
      }
    </>
  }

}

ProofPage.propTypes = {
  search: PropTypes.object,
}

//export default ShowFiles
export default withLocation(ProofPage)