import React from "react"
import PropTypes from "prop-types"
import { Box } from "adminlte-2-react"
import CircularProgress from "@material-ui/core/CircularProgress"

let _this
export class Sha256Hashe2e extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      inFetch: true,
      error: false,
      icon: '',
      sha256Hash: ''
    }
    _this = this
  }

  render() {
    const { inFetch, error, icon, sha256Hash } = _this.state
    return (
      <Box
        collapsable={true}
        title="Validate Sha256 Hash"
        icon={icon || ''}
        className={`fade-in proof-content ${!inFetch && error ?
          'proof-icon-wrong' :
          'proof-icon-check'}`
        } >

        <p>
          This is the SHA256 hash 'fingerprint' of the document stored on our
          server. If it does not match the hash above, then the document has
          been modied in some way.
        </p>

        {inFetch ? <CircularProgress className="proof-spinner" /> :
          <p>{sha256Hash}</p>
        }
        {error && <p>{error}</p>}

      </Box>
    )
  }
  componentDidMount() {
    setTimeout(() => {
      _this.compareHash()
    }, 1000);
  }
  // get sha256 hash from original file decrypted
  async  compareHash() {
    try {
      const { sha256Hash, sha256Proof } = _this.props
      if (!sha256Hash) {
        throw new Error('Hash not found')
      }
      if (sha256Hash !== sha256Proof) {
        throw new Error('Hash does not Match')
      }
      // handle test status
      _this.setState({
        inFetch: false,
        icon: 'fa-check',
        sha256Hash: sha256Hash
      })
      _this.props.nextStep()
    } catch (error) {
      // handle test status
      _this.setState({
        inFetch: false,
        icon: 'fa-times-circle',
        error: error.message
      })
      _this.props.nextStep(true)
    }
  }

}
Sha256Hashe2e.propTypes = {
  sha256Hash: PropTypes.string,
  sha256Proof: PropTypes.string,
  nextStep: PropTypes.func,
}
